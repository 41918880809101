<template>
  <div>
    <div class="column-row">
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <slideshow :slides="parentsSlides"></slideshow>
        </div>
      </div>
    </div>

    <div class="column-row">
      <div class="column column-large first last">
        <div class="column-top">
          <h1>LEGO Universe Closure Information</h1>
        </div>
        <div class="column-content">
          <div style="display: block; margin: 0px 0px 20px; color: #888888">
              <img style="PADDING-TOP: 40px" src="@/assets/the-game/minifigs.png">
            Hello LEGO Universe parents,<br />
            <br />

            We have announced that LEGO Universe will be closing on January 30,
            2012.
            <br /><br />
            This was a very difficult decision and we are very sorry knowing
            this will sadden many of the kids playing and enjoying the game
            every day.
            <br /><br />

            We would like to assure you as parents that we will continue to
            uphold the same high level of player safety standards during the
            final month of the game. This will include the same monitoring of
            chat, communication and user generated content as we have done up to
            this point.
            <br /><br />

            In addition, we will be keeping the lines of communication open
            through our message boards to give the players a chance to ask
            questions, give their opinions and to say goodbye to their friend
            from the game.
            <br /><br />

            Please read the
            <router-link to="/help/faq/359551">FAQs</router-link>
            for more information surrounding the closure, and thank you for
            being a LEGO Universe parent. We hope your children enjoyed having
            the adventures as much as we enjoyed creating them. <br /><br />

            Sincerely,
            <br /><br />

            The LEGO Universe Team
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PARENTS } from "@/stories/slides";
import Slideshow from "@/components/Slideshow.vue";

export default {
  data() {
    return {
      parentsSlides: PARENTS,
    }
  },
  computed: {},
  components: {
    Slideshow
  },
};
</script>