const PARENTS = [
    {
        id: 0,
        // cache.lego.com/upload/contentTemplating/UniverseSlideShowItems/images/picC3B4B9A5A21F276E3F21588A31E6E5A5.jpg
        img: require("@/assets/slideshow-items/game-closing.jpg"),
        headline2: "The Adventure ends...",
        text1: `<br>We are very sad to announce that LEGO Universe will
        be closing on January 30, 2012. <br><br>Please read the
        messsage below for more information.`
    }
]

const THE_GAME = [
    {
        id: 0,
        img: require("@/assets/slideshow-items/what-is-lego-universe.jpg"),
        headline2: "What is LEGO® Universe?",
        text1: `LEGO® Universe is a MMOG for LEGO kids and fans.<br><br>
• Build whatever you imagine<br><br>
• Battle the enemy<br><br>
• New content frequently<br><br>
• Adventure together with your friends<br><br>
• <router-link style="color: #3399cc" to="/media/258109">Watch video: What is LEGO Universe?</router-link>`,
    },
    {
        id: 1,
        img: require("@/assets/slideshow-items/whats-an-mmog.jpg"),
        headline2: "What's an MMOG?",
        text1: `MMOG stands for Massively Multiplayer Online Game. In a MMOG you are your
own character in an imaginary world with thousands of players from all
around the globe playing and exploring together or against each other.`
    },
    {
        id: 2,
        swf: "http://cache.lego.com/upload/contentTemplating/UniverseSlideShowItems/otherfiles/downloadF042EBE86F8197E6AD25C6EB5DE243A5.swf",
        img: require("@/assets/slideshow-items/safety-build.jpg"), // Guess!
        headline2: "Build dream creations",
        text1:
            '<font style="color: #FFFFFF">In LEGO&reg; Universe you have access to all LEGO bricks in all LEGO colors available. You can build in different ways and as you advance through the game new bricks and models will be rewarded.</font>',
    },
    {
        id: 3,
        swf: "http://cache.lego.com/upload/contentTemplating/UniverseSlideShowItems/otherfiles/downloadF1BADC11F3A0B16343FA93D0A40DABF4.swf",
        // Guess:
        img: require("@/assets/slideshow-items/X-battle-the-maelstrom.jpg"),
        headline1: "",
        headline2: "Battle the enemies",
        text1:
            '<font color="#FFFFFF">Battle the dark Maelstrom and its destructive creatures: the Stromlings!</font>',
    },
    {
        id: 4,
        img: require("@/assets/slideshow-items/new-missions-and-worlds.jpg"),
        headline2: "New missions and<br>worlds to explore",
        text1: `There will be new missions and worlds frequently.<br>To see the latest updates,
<router-link style="color: #3399cc" to="/community/ingameevents">click here</router-link>.`,
    },
    {
        id: 5,
        swf: "http://cache.lego.com/upload/contentTemplating/UniverseSlideShowItems/otherfiles/download8C16007B658F4FBC37156B0AFCF1E6BF.swf",
        img: require("@/assets/slideshow-items/X-explore-with-friends.jpg"), // Guess!
        headline1: '<font color="#FFFFFF"> </font>',
        headline2: 'Adventure together<br> with your friends',
        text1: '<font style="color: #FFFFFF;">Explore new worlds and save imagination together with your friends.</font>',
    },
    {
        id: 6,
        link: "/media/category/272318/section/148275/content/258111",
        img: require("@/assets/slideshow-items/how-to-start-playing.jpg"),
        headline2: "How do I start playing?",
        text1: "."
    }
];

export { THE_GAME, PARENTS };